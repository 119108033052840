.about-us{
    padding: var(--padding) 0;
    background-image: url(../../../public/assets/about-banner.png), 
    linear-gradient(180deg, #0A0A0A 0%, #0A0A0A 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.about-us .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.about-us .container .content,
.about-us .container .image{
    flex-basis: 48%;
}
.about-us .container .content .slogan{
    padding: 10px;
    display: flex;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, .1);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.about-us .container .content .slogan img{
    width: 14px;
}
.about-us .container .content .slogan span{
    color: var(--second_color);
    margin: 0 10px;
    letter-spacing: .36px;
    font-size: 13px;
    text-transform: uppercase;
}
.about-us .container .content h3{
    color: var(--second_color);
    font-size: 50px;
    margin: 20px 0;
}
.about-us .container .content p{
    color: var(--text_color);
    margin-top: 0;
    line-height: 1.6;
}
.about-us .container .image{
    text-align: right;
}
.about-us .container .image img{
    width: 350px;
}
.about-us .mission-vision-values-container{
    margin-top: 40px;
}
.about-us .mission-vision-values-container .box{
    background: rgba(255, 255, 255, .1);
    padding: 20px;
    min-height: 183px;
    flex-basis: 32%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.about-us .mission-vision-values-container .box:hover h4{
    color: var(--main_color);
}
.about-us .mission-vision-values-container .box h4{
    color: var(--second_color);
    margin-top: 0;
    font-size: 25px;
    margin-bottom: 15px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.about-us .mission-vision-values-container .box p{
    color: var(--second_color);
    line-height: 1.6;
    margin: 0;
    font-size: 14px;
}
@media (max-width: 991px) {
    .about-us .container{
        flex-direction: column-reverse;
    }
    .about-us .container .image{
        margin-bottom: 20px;
    }
    .about-us .container .image img{
        width: 150px;
    }
    .about-us .mission-vision-values-container{
        flex-direction: column;
    }
    .about-us .mission-vision-values-container .box{
        margin-bottom: 20px;
    }
}
footer{
    padding-top: var(--padding);
    padding-bottom: calc(var(--padding) / 2);
    background-color: var(--third_color);
}
footer .container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
footer .container .data-holder{
    flex-basis: 38%;
}
footer .container .data-holder img{
    width: 110px;
}
footer .container .data-holder .social{
    margin-top: 20px;
    display: flex;
}
footer .container .data-holder .social li{
    margin-right: 10px;
}
footer .container .data-holder .social li a svg{
    padding: 10px;
    width: 20px;
    height: 20px;
    background-color: var(--main_color);
    color: var(--second_color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
footer .container p{
    color: var(--text_color);
    line-height: 1.5;
    margin-bottom: 0;
}
footer .container div h2{
    margin-top: 0;
    color: var(--second_color);
    font-size: 25px;
    font-weight: 600;
}
footer .container div ul li{
    margin-bottom: 10px;
}
footer .container div ul li a{
    color: var(--text_color);
    font-size: 17px;
    font-weight: 500;
    line-height: 1.3;
}
footer .container div ul li a:hover{
    color: var(--main_color);
}
footer .copyright-container-holder{
    margin-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
footer .copyright-container-holder p{
    margin: 0;
    font-size: 13px;
}
footer .copyright-container-holder p a{
    font-weight: bold;
    color: var(--text_color);
}
footer .copyright-container-holder ul{
    display: flex;

}
footer .copyright-container-holder ul li{
    margin-left: 10px;
}
footer .copyright-container-holder ul li a{
    color: var(--text_color);
    font-size: 13px;
}
@media (max-width: 991px) {
    footer .container:not(.copyright-container-holder){
        flex-direction: column;
        gap: 30px;
    }
    footer .container .data-holder{
        width: 100%;
    }
    footer .copyright-container-holder{
        flex-direction: column-reverse;
        margin-top: 30px;
    }
    footer .copyright-container-holder p{
        margin-top: 10px;
    }
    footer .copyright-container-holder ul li{
        margin: 0 10px;
    
    }
}
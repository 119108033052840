.features{
    background-image: url(../../../public/assets/features/features-bg.png), 
    linear-gradient(180deg, var(--fourth_color) 0%, #0A0A0A 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: var(--padding) 0;
}

.features .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}
.features .box{
    background: rgba(255, 255, 255, .1);
    padding: 20px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.features .box img{
    height: 60px;
}
.features .box:hover h4{
    color: var(--main_color);
}
.features .box h4{
    color: var(--second_color);
    margin-top: 0;
    font-size: 25px;
    margin: 15px 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.features .box p{
    color: var(--text_color);
    line-height: 1.8;
    margin: 0;
}
/* Main Rules */
:root{
  --main_color: #3067FF;
  --second_color: #fff;
  --third_color: #000000;
  --fourth_color: #242627;
  --text_color: #B5B5B5;
  --padding: 60px;
}
*{
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body{
  width: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
img{
  max-width: 100%;
}
.container{
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .container{
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container{
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container{
    width: 1170px;
  }
}
.active{
  right: 0 !important;
}
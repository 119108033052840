.privacy-policy{
    padding: calc(var(--padding) * 2) 0;
}
.privacy-policy .container .content ul li{
    margin: 20px 0;
}
.privacy-policy .container .content .content-holder h3,
.privacy-policy .container .content ul li h4{
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    color: var(--main_color);
}
.privacy-policy .container .content .content-holder h3{
    font-size: 30px;
}
.privacy-policy .container .content .content-holder p,
.privacy-policy .container .content ul li p{
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
}
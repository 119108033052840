header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    padding: 15px 0;
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(12px);
    border-bottom: 1px solid #ffffff1a;
}
header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header .container .logo{
    width: 75px;
}
header .container .menu{
    display: flex;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
header .container .menu li{
    margin: 0 8px;
}
header .container .menu li a{
    color: var(--second_color);
    font-size: 17px;
    letter-spacing: .4px;
    font-weight: 500;
    position: relative;
}
header .container .menu li a:hover{
    color: var(--main_color);
}
header .container .started-btn{
    color: var(--text_color);
    padding: 15px 30px;
    background-color: var(--main_color);
    color: var(--second_color);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
header .container .started-btn:hover{
    background-color: var(--second_color);
    color: var(--main_color);
}
@media (min-width: 992px) {
    header .container .menu-icon{
        display: none;
    }
}
@media (max-width: 991px) {
    header .container .logo{
        width: 50px;
    }
    header .container .menu{
        position: absolute;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        top: 84.89px;
        right: 1500px;
        height: calc(100vh - 84.89px);
        width: 100%;
        background-color: var(--third_color);
    }
    header .container .menu li{
        margin: 20px 0;
    }
    header .container .menu li a{
        font-weight: bold;
        font-size: 25px;
    }
    header .container .menu-icon{
        color: var(--second_color);
        font-size: 20px;
        padding: 10px;
        border: 1px solid var(--second_color);
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }
    header .container .started-btn{
        display: none;
    }
}
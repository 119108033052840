.clients{
    padding: var(--padding) 0;
    background-color: var(--fourth_color);
}
.clients .container .holder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25px;
}
.clients .container .holder img{
    width: 100px;
    height: 50px;
}
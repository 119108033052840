.testimonials{
    padding: var(--padding) 0;
    background-color: var(--third_color);
}
.testimonials .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 20px;
}
.testimonials .container .testimonial{
    background-color: var(--fourth_color);
    padding: 35px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.testimonials .container .testimonial .client-data{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.testimonials .container .testimonial .client-data .client-img{
    width: 50px;
    height: 50px;
    margin-right: 20px;
    max-width: 100%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.testimonials .container .testimonial .client-data .name-position .name{
    color: var(--second_color);
    margin-top: 0;
    margin-bottom: 7px;
    font-size: 23px;
}
.testimonials .container .testimonial .client-data .name-position .position{
    color: var(--main_color);
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}
.testimonials .container .testimonial .feedback{
    color: var(--text_color);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: .54px;
    margin: 0;
}
@media (max-width: 991px) {
    .testimonials .container{
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
    .testimonials .container .testimonial .feedback{
        font-size: 15px;
    }
    .testimonials .container .testimonial .client-data{
        flex-direction: column;
    }
    .testimonials .container .testimonial .client-data .client-img{
        margin: 0;
        width: 70px;
        height: 70px;
    }
    .testimonials .container .testimonial .client-data .name-position{
        margin-top: 20px;
        text-align: center;
    }
    .testimonials .container .testimonial .client-data .name-position .position{
        font-size: 16px;
    }
}
.hero{
    background-image: url(../../../public/assets/hero-banner.png), 
    linear-gradient(180deg, #0A0A0A 0%, #0A0A0A 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.hero .container{
    height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero .container .slogan{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, .1);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.hero .container .slogan img{
    width: 14px;
}
.hero .container .slogan span{
    color: var(--second_color);
    margin: 0 10px;
    letter-spacing: .36px;
    font-size: 13px;
    text-transform: uppercase;
}
.hero .container .content{
    margin: 30px 0;
}
.hero .container .content  h1{
    margin: 0;
    font-size: 70px;
    padding: calc(var(--padding) / 2) 0;
    color: var(--second_color);
    text-align: center;
    text-transform: capitalize;
}
.hero .container .content h1 span{
    color: var(--main_color);
    text-decoration: underline;
}
.hero .container .content p{
    color: var(--text_color);
    font-size: 18px;
    line-height: 1.6;
    margin: 0;
    text-align: center;
}
.hero .container .btns{
    margin-top: calc(var(--padding) / 2);
}
.hero .container .btns a{
    margin-left: 20px;
    color: var(--second_color);
    border: 1px solid var(--second_color);
    padding: 15px 30px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.hero .container .btns a:first-child{
    margin: 0;
    background-color: transparent;
    color: var(--second_color);
}
.hero .container .btns a:first-child:hover{
    background-color: var(--main_color);
    border-color: var(--main_color);
}
.hero .container .btns a:last-child{
    background-color: var(--main_color);
    border-color: var(--main_color);
}
.hero .container .btns a:last-child:hover{
    background-color: transparent;
    border-color: var(--second_color);

}
@media (max-width: 991px) {
    .hero .container .content{
        margin: 25px 0;
    }
    .hero .container .content h1{
        font-size: 38px;
    }
    .hero .container .content p{
        font-size: 16px;
    }
}
.services{
    padding: var(--padding) 0;
    background-color: var(--third_color);
}
.services .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}
.services .container .serv{
    padding: 25px;
    background-color: var(--fourth_color);
    border-radius: 10px;
    transition: 0.3s;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.services .container .serv:hover{
    background-color: var(--main_color);
}
.services .container .serv:hover p{
    color: var(--second_color);
}
.services .container .serv h3{
    margin: 20px 0;
    color: var(--second_color);
    font-size: 25px;
    letter-spacing: .36px;
}
.services .container .serv img{
    width: 80px;
}
.services .container .serv p{
    color: var(--text_color);
    line-height: 1.8;
    margin: 0;
}
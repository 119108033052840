.contact-us{
    padding: var(--padding) 0;
    background-color: var(--fourth_color);
}
.contact-us .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contact-us .container .info{
    flex-basis: 48%;
}
.contact-us .container .info .main-heading{
    margin-bottom: 0;
    align-items: flex-start;
}
.contact-us .container .info .main-heading .heading{
    text-align: left;
}
.contact-us .container .info .call-mail{
    display: flex;
    flex-direction: column;

}
.contact-us .container .info .call-mail .holder{
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.contact-us .container .info .call-mail .holder svg{
    color: var(--main_color);
    width: 30px;
    height: 30px;
    margin-right: 15px;
    
}
.contact-us .container .info .call-mail .holder div span{
    color: #cacaca;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 1; */
    margin-bottom: 7px;
    display: block;
}
.contact-us .container .info .call-mail .holder div a{
    color: var(--second_color);
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
}
.contact-us .container .info .social{
    margin-top: 20px;
    display: flex;
}
.contact-us .container .info .social li{
    margin-right: 10px;
}
.contact-us .container .info .social li a svg{
    padding: 10px;
    width: 20px;
    height: 20px;
    background-color: var(--main_color);
    color: var(--second_color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.contact-us .container form{
    flex-basis: 48%;
    padding: 40px;
    background-color: var(--third_color);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.contact-us .container form h3{
    color: var(--second_color);
    border-bottom: 1px solid #ffffff1a;
    margin-top: 0;
    font-size: 24px;
    padding-bottom: 15px;
    margin-bottom: 35px;
}
.contact-us .container form .input-holder{
    margin-bottom: 20px;
}
.contact-us .container form .input-holder input{
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    padding: 20px;
    background-color: var(--fourth_color);
    color: var(--second_color);
    border: none;
    outline: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.contact-us .container form button{
    border-radius: 5px;
    width: 120px;
    background-color: var(--main_color);
    font-weight: 600;
    font-size: 16px;
    color: var(--second_color);
    letter-spacing: .48px;
    line-height: 1;
    padding: 16px 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.contact-us .container form button:hover{
    background-color: #244bb5;
}
@media (max-width: 991px) {
    .contact-us .container{
        flex-direction: column;
    }
    .contact-us .container form{
        width: 100%;
        padding: 35px;
    }
    .contact-us .container .info{
        text-align: center;
        margin-bottom: 40px;
    }
    .contact-us .container .info .call-mail .holder div span{
        text-align: left;
    }
    .contact-us .container .info .main-heading .heading h2{
        font-size: 33px;
    }
    .contact-us .container .info .main-heading .heading p{
        text-align: left;
        margin: 0;
    }
}
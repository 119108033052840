.main-heading{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}
.main-heading .slogan{
    padding: 10px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, .1);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.main-heading .slogan img{
    width: 14px;
}
.main-heading .slogan span{
    color: var(--second_color);
    margin: 0 10px;
    letter-spacing: .36px;
    font-size: 13px;
    text-transform: uppercase;
}
.main-heading .heading{
    text-align: center;
}
.main-heading .heading h2{
    color: var(--second_color);
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2;
    margin: 20px 0;
}
.main-heading .heading p{
    margin: 0;
    color: var(--text_color);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
}
@media (max-width: 991px) {
    .main-heading .heading h2{
        font-size: 38px;
    }
    .main-heading .heading p{
        margin: 0 15px;
        text-align: center;
        font-size: 14px;
    }
}
.error-page{
    height: 100vh;
    background-color: var(--main_color);
}
.error-page .container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.error-page .container h2{
    font-size: 200px;
    margin: 0;
    color: var(--second_color);
}
@media (max-width: 991px) {
    .error-page .container h2{
        font-size: 150px;
    }
}
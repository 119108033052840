.counter{
    background-color: var(--main_color);
    padding: var(--padding) 0;
}
.counter .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}
.counter .container .box{
    color: var(--second_color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.counter .container .box .image-holder{
    margin-right: 20px;
}
.counter .container .box img{
    height: 60px;
}
.counter .container .box h3{
    font-size: 40px;
    margin-top: 0;
    margin: 0;
}
.counter .container .box p{
    margin: 0;
}
@media (max-width: 991px) {
    .counter .container .box{
        flex-direction: column;
        text-align: center;
    }
    .counter .container .box{
        margin-bottom: 20px;
    }
    .counter .container .box .image-holder{
        margin: 0;
    }
}
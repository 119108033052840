.technologies{
    padding: var(--padding) 0;
    background-color: var(--fourth_color);
}
.technologies .container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
}
.technologies .container .box{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 25px;
    border-radius: 5px;
    transition: 0.3s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
.technologies .container .box:hover{
    background-color: var(--second_color);
}
.technologies .container .box img{
    height: 50px;
    max-width: 100%;
}
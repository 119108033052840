.marquee{
    background-color: #1e1f1f;
    padding: calc(var(--padding) / 3) 0;
}
.marquee .holder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}
.marquee .holder img{
    width: 17px;
    height: 17px;
    margin-right: 25px;
}
.marquee .holder h3{
    color: var(--text_color);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .36px;
    text-transform: uppercase;
    margin: 0;
}